
import { defineComponent } from 'vue'
import TmExpansion from '@/components/shared/TmExpansion.vue'

export default defineComponent({
  components: {
    TmExpansion,
  },
  props: {
    price: {
      type: String,
    },
  },
})
