
import { defineComponent, ref } from 'vue'
import GettingStarted from '@/components/pages/gettingStarted/GettingStarted.vue'
import VirtualNumber from '@/components/pages/gettingStarted/gettingStartedBlocks/VirtualNumber.vue'
import SenderId from '@/components/pages/gettingStarted/gettingStartedBlocks/SenderId.vue'
import CpassAccount from '@/components/pages/gettingStarted/gettingStartedBlocks/CpassAccount.vue'
import Whatsapp from '@/components/pages/gettingStarted/gettingStartedBlocks/Whatsapp.vue'
import FaqBlock from '@/components/shared/templates/FaqBlock.vue'
import GettingStartedFaqVirtualNumbersUk from '@/components/pages/gettingStarted/GettingStartedFaqVirtualNumbersUk.vue'
import GettingStartedFaqSenderIds from '@/components/pages/gettingStarted/GettingStartedFaqSenderIds.vue'
import GettingStartedFaqCpaas from '@/components/pages/gettingStarted/GettingStartedFaqCpaas.vue'
import GettingStartedFaqWhatsapp from '@/components/pages/gettingStarted/GettingStartedFaqWhatsapp.vue'

export default defineComponent({
  components: {
    GettingStarted,
    VirtualNumber,
    SenderId,
    CpassAccount,
    Whatsapp,
    FaqBlock,
    GettingStartedFaqVirtualNumbersUk,
    GettingStartedFaqSenderIds,
    GettingStartedFaqCpaas,
    GettingStartedFaqWhatsapp,
  },
  setup() {
    const gettingStartedRef = ref()
    const price = '£10'
    const tabs = ref([
      {
        name: 'virtual-numbers',
        label: 'Virtual numbers',
      },
      {
        name: 'sender-ids',
        label: 'Sender IDs',
      },
      {
        name: 'cpaas',
        label: 'CPaaS integrations',
      },
      {
        name: 'whatsapp',
        label: 'WhatsApp',
      },
    ])

    const currentTab = ref(tabs.value[0].name)

    const virtualNumber = () => {
      currentTab.value = 'virtual-numbers'
      gettingStartedRef.value.scrollTo()
    }

    return {
      gettingStartedRef,
      price,
      tabs,
      currentTab,
      virtualNumber,
    }
  },
})
