
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import GettingStartedBlock from '@/components/pages/gettingStarted/GettingStartedBlock.vue'

export default defineComponent({
  components: {
    TmButton,
    GettingStartedBlock,
  },
  props: {
    title: {
      type: String,
    },
  },
  emits: ['onLearnMoreClick'],
})
