
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmEmoji from '@/components/shared/TmEmoji.vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'

export default defineComponent({
  name: 'Getting Started',
  components: {
    TmButton,
    TmEmoji,
    PageContent,
  },
  setup() {
    const faq = ref<HTMLElement | null>(null)
    const scrollTo = () => {
      faq.value?.scrollIntoView({ behavior: 'smooth' })
    }

    return {
      faq,
      scrollTo,
    }
  },
})
