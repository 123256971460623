
import { defineComponent } from 'vue'
import GettingStartedBlock from '@/components/pages/gettingStarted/GettingStartedBlock.vue'

export default defineComponent({
  components: {
    GettingStartedBlock,
  },
  props: {
    price: {
      type: String,
    },
  },
})
